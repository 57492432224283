import { cloneElement } from "react";
import Reports from ".";
import { CustomReportProvider } from "./hooks/customReportData.hook";
import { FieldsToHideProvider } from "./hooks/fieldsToHide.hook";
import { TranslationProvider } from "./hooks/translation.hook";
import SendVisibilities from "./sendVisibilities";
import { useLocation } from "react-router-dom";

const RouteWithReload = ({ children }) => {
  const location = useLocation();

  return cloneElement(children, { key: location.pathname });
};

export const reportRoutes = [
  {
    path: "/reports/operational-pipeline",
    element: (
      <RouteWithReload>
        <TranslationProvider>
          <FieldsToHideProvider>
            <CustomReportProvider ID_RELATORIO={1}>
              <Reports
                key={1}
                ID_RELATORIO={1}
                title='Reports: Operational - Pipeline'
              />
            </CustomReportProvider>
          </FieldsToHideProvider>
        </TranslationProvider>
      </RouteWithReload>
    ),
  },
  {
    path: "/reports/operational-discrepancy",
    element: (
      <RouteWithReload>
        <TranslationProvider>
          <FieldsToHideProvider>
            <CustomReportProvider ID_RELATORIO={2}>
              <Reports
                key={2}
                ID_RELATORIO={2}
                title='Reports: Operational - Discrepancy'
              />
            </CustomReportProvider>
          </FieldsToHideProvider>
        </TranslationProvider>
      </RouteWithReload>
    ),
  },
  {
    path: "/reports/operational-pending",
    element: (
      <RouteWithReload>
        <TranslationProvider>
          <FieldsToHideProvider>
            <CustomReportProvider ID_RELATORIO={3}>
              <Reports
                key={3}
                ID_RELATORIO={3}
                title='Reports: Operational - Pending'
              />
            </CustomReportProvider>
          </FieldsToHideProvider>
        </TranslationProvider>
      </RouteWithReload>
    ),
  },
  {
    path: "/reports/operational-missing",
    element: (
      <RouteWithReload>
        <TranslationProvider>
          <FieldsToHideProvider>
            <CustomReportProvider ID_RELATORIO={4}>
              <Reports
                key={4}
                ID_RELATORIO={4}
                title='Reports: Operational - Missing'
              />
            </CustomReportProvider>
          </FieldsToHideProvider>
        </TranslationProvider>
      </RouteWithReload>
    ),
  },
  {
    path: "/reports/operational-receiving-control",
    element: (
      <RouteWithReload>
        <TranslationProvider>
          <FieldsToHideProvider>
            <CustomReportProvider ID_RELATORIO={5}>
              <Reports
                key={5}
                ID_RELATORIO={5}
                title='Reports: Operational - Receiving Control'
              />
            </CustomReportProvider>
          </FieldsToHideProvider>
        </TranslationProvider>
      </RouteWithReload>
    ),
  },
  {
    path: "/reports/customs-audit",
    element: (
      <RouteWithReload>
        <TranslationProvider>
          <FieldsToHideProvider>
            <CustomReportProvider ID_RELATORIO={6}>
              <Reports
                key={6}
                ID_RELATORIO={6}
                title='Reports: Operational - Customs Audit'
              />
            </CustomReportProvider>
          </FieldsToHideProvider>
        </TranslationProvider>
      </RouteWithReload>
    ),
  },
  {
    path: "/reports/operational-missing-entry-form",
    element: (
      <RouteWithReload>
        <TranslationProvider>
          <FieldsToHideProvider>
            <CustomReportProvider ID_RELATORIO={7}>
              <Reports
                key={7}
                ID_RELATORIO={7}
                title='Reports: Operational - Missing Entry Form'
              />
            </CustomReportProvider>
          </FieldsToHideProvider>
        </TranslationProvider>
      </RouteWithReload>
    ),
  },
  {
    path: "/reports/invoice-migo",
    element: (
      <RouteWithReload>
        <TranslationProvider>
          <FieldsToHideProvider>
            <CustomReportProvider ID_RELATORIO={8}>
              <Reports
                key={8}
                ID_RELATORIO={8}
                title='Reports: Invoice MIGO'
              />
            </CustomReportProvider>
          </FieldsToHideProvider>
        </TranslationProvider>
      </RouteWithReload>
    ),
  },
  {
    path: "/reports/triage-picking-list",
    element: (
      <RouteWithReload>
        <TranslationProvider>
          <FieldsToHideProvider>
            <CustomReportProvider ID_RELATORIO={10}>
              <Reports
                key={10}
                ID_RELATORIO={10}
                title='Reports: Triage - Picking List'
              />
            </CustomReportProvider>
          </FieldsToHideProvider>
        </TranslationProvider>
      </RouteWithReload>
    ),
  },
  {
    path: "/reports/triage-pipeline",
    element: (
      <RouteWithReload>
        <TranslationProvider>
          <FieldsToHideProvider>
            <CustomReportProvider ID_RELATORIO={9}>
              <Reports
                key={9}
                ID_RELATORIO={9}
                title='Reports: Triage - Pipeline'
              />
            </CustomReportProvider>
          </FieldsToHideProvider>
        </TranslationProvider>
      </RouteWithReload>
    ),
  },
  {
    path: "/reports/inventory",
    element: (
      <RouteWithReload>
        <TranslationProvider>
          <FieldsToHideProvider>
            <CustomReportProvider ID_RELATORIO={12}>
              <Reports
                key={12}
                ID_RELATORIO={12}
                title='Reports: Inventory'
              />
            </CustomReportProvider>
          </FieldsToHideProvider>
        </TranslationProvider>
      </RouteWithReload>
    ),
  },
  {
    path: "/reports/general",
    element: (
      <RouteWithReload>
        <TranslationProvider>
          <FieldsToHideProvider>
            <CustomReportProvider ID_RELATORIO={11}>
              <Reports
                key={11}
                ID_RELATORIO={11}
                title='Reports: General'
              />
            </CustomReportProvider>
          </FieldsToHideProvider>
        </TranslationProvider>
      </RouteWithReload>
    ),
  },
  {
    path: `/reports/send-visibilities`,
    element: (
      <RouteWithReload>
        <SendVisibilities key={13}/>
      </RouteWithReload>
    )
  }
];
