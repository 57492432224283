import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import ReplayIcon from '@mui/icons-material/Replay';
import { Accordion } from "../../../components/Accordion/Accordion";
import { AccordionSummary } from "../../../components/Accordion/AccordionSummary";
import { AccordionDetails, Box, Button, Dialog, DialogActions, DialogContent,DialogContentText, DialogTitle, Grid, MenuItem, TextField, Tooltip, Typography } from "@mui/material";
import React, { useMemo, useRef, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { findWarehouseByWrOrInvoiceNumber } from "../../../services/warehouse"
import LoadingOverlay from "../../../components/LoadingOverlay";
import { Warehouse } from "../../../types/warehouse";
import { useNavigate } from "react-router-dom";
import { ComboBoxType } from "../../../types/general";
import { InfoOutlined } from "@mui/icons-material";
import DataTableDefault from "../../../components/DataTable";
import dayjs from "dayjs";

function mergeInvoices(invoices1: any[], invoices2: any[]) {
  const map = new Map();
  invoices1.forEach(invoice => map.set(invoice.ID_INVOICE, invoice));
  invoices2.forEach(invoice => map.set(invoice.ID_INVOICE, invoice));
  return Array.from(map.values());
}

function mergeWarehouses(arr1: { ID_WAREHOUSE: any; }[], arr2: { ID_WAREHOUSE: any; invoices: any; }[]) {
  const result: any[] = [];
  const warehouseMap = new Map();
  arr1.forEach((warehouse: { ID_WAREHOUSE: any; }) => {
    warehouseMap.set(warehouse.ID_WAREHOUSE, { ...warehouse });
  });

  arr2.forEach((warehouse: { ID_WAREHOUSE: any; invoices: any; }) => {
    if (warehouseMap.has(warehouse.ID_WAREHOUSE)) {
      const existingWarehouse = warehouseMap.get(warehouse.ID_WAREHOUSE);
      existingWarehouse.invoices = mergeInvoices(existingWarehouse.invoices, warehouse.invoices);
    } else {
      warehouseMap.set(warehouse.ID_WAREHOUSE, { ...warehouse });
    }
  });
  warehouseMap.forEach(warehouse => result.push(warehouse));

  return result;
}

type ProcessInvoiceProps = {
  warehouses: Warehouse[],
  setWarehouses: React.Dispatch<React.SetStateAction<Warehouse[]>>,
  deletedInvoiceIds: number[],
  setDeletedInvoiceIds: React.Dispatch<React.SetStateAction<number[]>>,
  centerRef: React.MutableRefObject<HTMLInputElement | null>,
  dataETDRef: React.MutableRefObject<HTMLInputElement | null>,
  freightForwaderRef: React.MutableRefObject<HTMLInputElement | null>,
  serviceLevelRef: React.MutableRefObject<HTMLInputElement | null>,
  watch: any,
  centers: ComboBoxType[]
  disabledByInvoiceAllFields?: boolean
}

type ProcessInvoiceGridItemProps = {
  plant: string,
  WR: string,
  invoices: { id: number, num: string }[],
  customersRegime?: string,
  suppliers: string,
  removeWarehouse: (NUM_WAREHOUSE: string) => void,
  showInvoiceDetails: (NUM_WAREHOUSE: string, ID_INVOICE?: number) => void,
  isInvoiceDetailsDialogOpen: boolean,
  setIsInvoiceDetailsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>,
  isSearchDisabled: boolean
  disabledByInvoiceAllFields?: boolean
}

type ProcessInvoiceDetailsDialogProps = {
  open: boolean,
  handleClose: () => void,
  invoices: {
    id: number,
    num: string
  }[],
  showInvoiceDetails: (NUM_WAREHOUSE: string, ID_INVOICE?: number) => void,
  NUM_WAREHOUSE: string,
}

export default function ProcessInvoice({
  warehouses,
  setWarehouses,
  deletedInvoiceIds,
  setDeletedInvoiceIds,
  centers,
  watch,
  disabledByInvoiceAllFields
}: ProcessInvoiceProps) {
  const wrOrInvoiceNumRef = useRef<HTMLInputElement | null>(null);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [oldWarehousesState, setOldWarehousesState] = useState<Warehouse[]>([]);
  const [isInvoiceDetailsDialogOpen, setIsInvoiceDetailsDialogOpen] = useState<boolean>(false);

  const [centerSymble, dataETD, freightFowarederId, serviceLevelId, brokerId, modalId, currencyId, customRegimeId] = watch(['SIGLA_PLANTA_CONSOLID', 'DATA_ETD', 'ID_PARCEIRO_AGENTE_CARGAS', 'ID_PRIORIDADE', 'ID_PARCEIRO_BROKER', 'ID_MODAL', 'ID_MOEDA', 'ID_REGIME']);

  const isSearchDisabled = useMemo(() => {
    return !centerSymble || !dataETD || !freightFowarederId || !serviceLevelId || !brokerId || !modalId || !currencyId || !customRegimeId;
  }, [wrOrInvoiceNumRef?.current?.value, centerSymble, dataETD, freightFowarederId, serviceLevelId, brokerId, modalId, currencyId]);

  const onInvoiceOrWRSearch = async () => {
    try {
      setIsLoading(true);
      if (isSearchDisabled) {
        enqueueSnackbar(
          "You must select Center/Plant, WR/Invoice number, ETD Date, Freight Forwarder, Service Level, Broker, Modal, Currency and Custom Regime to be able to search for and invoice",
          { variant: "error" }
        );
        return;
      }

      const wrOrInvoiceNum = wrOrInvoiceNumRef?.current?.value;
      const { id: idCentro, description } = centers.find((center: ComboBoxType) => center.description.indexOf(centerSymble ?? '') > -1) ?? {};
      const idFreightForwarder = Number(freightFowarederId);
      const idPrioridade = serviceLevelId;
      const idBroker = brokerId;
      const idModal = modalId;
      const idCurrency = currencyId;
      const idCustomRegime = customRegimeId;
      const data = await findWarehouseByWrOrInvoiceNumber(
        wrOrInvoiceNum!,
        +idCentro!,
        description?.substring(7, 10)!,
        idFreightForwarder,
        dataETD,
        idPrioridade,
        idBroker,
        idModal,
        idCurrency,
        idCustomRegime
      );

      setOldWarehousesState([...warehouses]);

      setWarehouses((oldState) => mergeWarehouses(oldState, data.object));

      if (!data.success) {
        enqueueSnackbar(data.message[0], { variant: "error" });
        return;
      }

      enqueueSnackbar("WR/Invoice found, save the boarding process to associate it", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Error searching invoices", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  }

  const removeWarehouse = (NUM_WAREHOUSE: string) => {
    const index = warehouses.findIndex((warehouse) => warehouse.NUM_WAREHOUSE === NUM_WAREHOUSE);
    const warehouse = warehouses[index];

    const invoiceIds: number[] = deletedInvoiceIds || [];
    warehouse.invoices.forEach((invoice) => {
      invoiceIds.push(invoice.ID_INVOICE!);
    });

    setDeletedInvoiceIds([...invoiceIds]);

    const warehousesData = [...warehouses];

    setOldWarehousesState([...warehouses]);

    warehousesData.splice(index, 1);

    setWarehouses([...warehousesData]);
  }

  const resetWarehousesToOldState = () => {
    setWarehouses([...oldWarehousesState]);
    const invoicesToDeleteAfterReset: number[] = [];
    warehouses.forEach((warehouse) => {
      warehouse.invoices.forEach((invoice) => {
        deletedInvoiceIds.forEach((invoiceId) => {
          if (invoiceId !== invoice.ID_INVOICE) {
            invoicesToDeleteAfterReset.push(invoiceId!);
          }
        })
      });
    });

    setDeletedInvoiceIds([...invoicesToDeleteAfterReset]);
  }

  const showInvoiceDetails = (NUM_WAREHOUSE: string, ID_INVOICE?: number) => {
    if (ID_INVOICE) {
      navigate(`/invoice/details/${ID_INVOICE}`);
      return;
    }

    const warehouse = warehouses.find((warehouse) => warehouse.NUM_WAREHOUSE === NUM_WAREHOUSE);

    if (!warehouse) {
      return;
    }

    navigate(`/invoice/details/${warehouse.invoices[0].ID_INVOICE}`);
    return;
  }

  return (
    <Accordion
      sx={{
        '&.MuiAccordion-root': {
          border: 0,
          marginBottom: 3,
          marginTop: 7
        },
      }}
    >
      {isLoading && <LoadingOverlay isLoading={true} />}
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <Typography>Invoice</Typography>
      </AccordionSummary>
      <AccordionDetails>

        {!disabledByInvoiceAllFields && <Box display="flex" alignItems="center" marginTop={3} justifyContent="space-between" flexDirection="row" gap={2}>
          <Box display="flex" alignItems="center" flexDirection="row" gap={1}>
            <TextField
              type="tel"
              inputRef={wrOrInvoiceNumRef}
              label="WR/Invoice"
              size="small"
            />

            <Button
              variant="contained"
              style={{ padding: "8px 16px" }}
              onClick={() => onInvoiceOrWRSearch()}
              disabled={isSearchDisabled}
            >
              <AddIcon />
            </Button>
            {isSearchDisabled && (
              <Tooltip title="You must select Center/Plant, WR/Invoice number, ETD Date, Freight Forwarder, Service Level, Broker, Modal, Currency and Custom Regime to be able to search for an invoice or warehouse">
                <InfoOutlined color="info" />
              </Tooltip>
            )}
          </Box>
          <Button
            variant="outlined"
            style={{ padding: "8px 11px", borderRadius: 50, color: "#b39ddb", borderColor: "#b39ddb" }}
            onClick={() => resetWarehousesToOldState()}
          >
            <ReplayIcon />
          </Button>
        </Box>}
        <Grid container paddingY={1} style={{ backgroundColor: "gray", marginTop: 25 }}>
          <Grid item xs={12 / 12}>
          </Grid>
          <Grid item xs={12 / 6}>
            <Typography>Plant</Typography>
          </Grid>
          <Grid item xs={12 / 6}>
            <Typography>WR</Typography>
          </Grid>
          <Grid item xs={12 / 6}>
            <Typography>Invoices</Typography>
          </Grid>
          <Grid item xs={12 / 6}>
            <Typography>Customers Regime</Typography>
          </Grid>
          <Grid item xs={12 / 6}>
            <Typography>Suppliers</Typography>
          </Grid>
          <Grid item xs={12 / 12}>
          </Grid>
        </Grid>
        {warehouses.map((warehouse) => (
          <ProcessInvoiceGridItem
            WR={warehouse.NUM_WAREHOUSE}
            customersRegime={warehouse.invoices?.length ? warehouse.invoices[0].customsRegime?.DESCRICAO_REGIME : ''}
            invoices={warehouse.invoices.map((invoice) => ({
              id: invoice?.ID_INVOICE!,
              num: invoice?.NUM_INVOICE,
              po: invoice?.ID_PO,
              date: invoice?.DATA_INVOICE && dayjs(invoice?.DATA_INVOICE).format(),
              wr: warehouse.NUM_WAREHOUSE
            }))}
            plant={warehouse.invoices?.length ? warehouse.invoices[0].centro?.DSC_SIGLA : ''}
            suppliers={warehouse.invoices?.length ? warehouse.invoices[0].fornecedor.NOME_FORN : ''}
            removeWarehouse={removeWarehouse}
            showInvoiceDetails={showInvoiceDetails}
            isInvoiceDetailsDialogOpen={isInvoiceDetailsDialogOpen}
            setIsInvoiceDetailsDialogOpen={setIsInvoiceDetailsDialogOpen}
            disabledByInvoiceAllFields={disabledByInvoiceAllFields}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  )
}

const ProcessInvoiceGridItem = ({
  plant,
  WR,
  invoices,
  customersRegime,
  suppliers,
  removeWarehouse,
  showInvoiceDetails,
  isInvoiceDetailsDialogOpen,
  setIsInvoiceDetailsDialogOpen,
  isSearchDisabled,
  disabledByInvoiceAllFields
}: ProcessInvoiceGridItemProps) => {
  const [open, setOpen] = useState(false);

  const invoicesNum = useMemo(() => {
    const nums = invoices.map((invoice) => invoice.num);
    return nums.join(', ');
  }, [invoices]);

  const showInvoiceDetailsCondition = () => {
    if (invoices.length === 1) {
      showInvoiceDetails(WR);
      return;
    }

    setOpen(true);
  }

  return (
    <Grid container key={Number(WR)} style={{ marginTop: 7 }}>
      <ProcessInvoiceDetailsDialog
        open={open}
        handleClose={() => setOpen(false)}
        invoices={invoices}
        showInvoiceDetails={showInvoiceDetails}
        NUM_WAREHOUSE={WR}
        isSearchDisabled={isSearchDisabled}
      />
      <Grid item xs={12 / 12} display="flex" justifyContent="center">
        <Button onClick={() => showInvoiceDetailsCondition()} style={{ color: "black" }}>
          <SearchIcon />
        </Button>
      </Grid>
      <Grid item xs={12 / 6}>
        <Typography>{plant}</Typography>
      </Grid>
      <Grid item xs={12 / 6}>
        <Typography>{WR}</Typography>
      </Grid>
      <Grid item xs={12 / 6}>
        <Typography>{invoicesNum}</Typography>
      </Grid>
      <Grid item xs={12 / 6}>
        <Typography>{customersRegime}</Typography>
      </Grid>
      <Grid item xs={12 / 6}>
        <Typography>{suppliers}</Typography>
      </Grid>
      <Grid item xs={12 / 12} display="flex" justifyContent="center">
        {!disabledByInvoiceAllFields && (<Button disabled={disabledByInvoiceAllFields} onClick={() => removeWarehouse(WR)}>
          <DeleteIcon style={{ color: "red" }} />
        </Button>)}
      </Grid>
    </Grid>
  )
}

const ProcessInvoiceDetailsDialog = ({
  open,
  handleClose,
  invoices,
  showInvoiceDetails,
  NUM_WAREHOUSE
}: ProcessInvoiceDetailsDialogProps) => {
  const navigate = useNavigate();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      scroll='paper'
      fullWidth={true}
      maxWidth='lg'
    >
      <DialogTitle id="alert-dialog-title">
        Select Data
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <DataTableDefault
            rowId={invoices.map(({ id }) => id)}
            data-testid="custom-regime-data-table"
            data={invoices}
            rowCount={invoices.length}
            pageSizeOptions={[]}
            initialState={{}}
            columns={[
              {
                field: "id",
                headerName: "ID",
                headerClassName: "super-app-theme--header",
                cellClassName: 'dashboard-cell',
                width: 150,
              },
              {
                field: "num",
                headerName: "INVOICE",
                headerClassName: "super-app-theme--header",
                cellClassName: 'dashboard-cell',
                flex: 1,
              },
              {
                field: "date",
                headerName: "INVOICE DATE",
                headerClassName: "super-app-theme--header",
                cellClassName: 'dashboard-cell',
                flex: 1,
                renderCell: ({ row }: any) => (row.date && dayjs(row.date).format('MM/DD/YYYY'))
              },
              {
                field: "po",
                headerName: "PO",
                headerClassName: "super-app-theme--header",
                cellClassName: 'dashboard-cell',
                flex: 1,
              },
              {
                field: "wr",
                headerName: "WR",
                headerClassName: "super-app-theme--header",
                cellClassName: 'dashboard-cell',
                flex: 1,
              },
              {
                field: "open",
                type: "actions",
                headerName: "",
                headerClassName: "super-app-theme--header",
                cellClassName: 'dashboard-cell',
                sortable: false,
                width: 120,
                renderCell: ({ row }: any) => (
                  <Button
                    key={`open-${row?.id}`}
                    onClick={() => navigate(`/invoice/details/${row?.id}`)}
                    style={{ border: '1px solid', borderRadius: '5px', paddingTop: 0, paddingBottom: 0, fontSize: '12px' }}
                  >
                    VIEW
                  </Button>
                )
              },
            ]}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}