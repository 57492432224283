import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import Search from "./components/Search";
import { useList } from "../../../services/ediFile";
import { getLocal, getToken } from "../../../storage/auth";
import DataTable from "./components/DataTable";
import useForms from "../../utils/forms/useForm.hook";

interface IParams {
  page: number;
  keyword: string;
  sort: string;
  limit: number;
  uri: string;
  param: string;
}

export default function EdiFile() {
  const [userValidation, setUserValidation] = useState<string>();
  const [token, setToken] = useState<string>();
  const getTokenSession = () => {
    const getTokens = (getToken()) as any;
    const getUser = (getLocal("userInfo")) as any;
    setToken(getTokens);
    setUserValidation(JSON.parse(getUser));
  };

  useEffect(() => {
    getTokenSession();
  }, []);

  const [params, setParams] = useState<IParams>({
    page: 1,
    limit: 10,
  } as IParams);

  const { data, meta, isLoading } = useList(params, token);

  const {
    paginationModel,
    setPaginationModel,
    handleRequest,
  } = useForms({
    data,
    params,
    setParams
  });

  const handleOrderChange = (params) => {
    if(params[0].field === 'fileName') {
      handleRequest({ orderField: 'ediFile.NOME_ARQUIVO', order: params[0].sort })
    } else if(params[0].field === 'fileData') {
      handleRequest({ orderField: 'ediFile.DATA_ENVIO_RECEB', order: params[0].sort })
    } else if(params[0].field === 'logFileName') {
      handleRequest({ orderField: 'logs.NOME_ARQUIVO_LOG', order: params[0].sort })
    } else if(params[0].field === 'logFileData') {
      handleRequest({ orderField: 'logs.DATA_ENVIO_RECEB.', order: params[0].sort })
    } else if(params[0].field === 'fileType') {
      handleRequest({ orderField: 'ediFile.ID_EDI_TIPO_ARQUIVO', order: params[0].sort })
    } else if(params[0].field === 'fileStatus') {
      handleRequest({ orderField: 'ediFile.ID_EDI_STATUS_ARQUIVO', order: params[0].sort })
    } else if(params[0].field === 'fileMessage') {
      handleRequest({ orderField: 'ediFile.MENSAGEM', order: params[0].sort })
    }
  }

  return (
    <Grid marginTop={4}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={10}>
          <Typography variant="h5">Edi File - Consult</Typography>
        </Grid>
      </Grid>
      <Search
        setParams={setParams}
      />
      <Grid>
          <DataTable
            loading={isLoading}
            data={data}
            center={[]}
            userValidation={userValidation}
            rowCount={meta.total || 0}
            onPaginationModelChange={(e: any) => {
              setPaginationModel(e);
              handleRequest({ page: e.page + 1, limit: e.pageSize });
            }}
            paginationModel={paginationModel}
            pagination
            initialState={{
              pagination: { paginationModel: { pageSize: params.limit } },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
            paginationMode="server"
            onSortModelChange={(param) => handleOrderChange(param)}
          />
      </Grid>
    </Grid>
  );
}
