import { useMemo } from 'react'
import IconButton from '@mui/material/IconButton'
import { Inventory, Folder, BorderColorSharp } from '@mui/icons-material'
import { Box, Grid, Tooltip, Typography } from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import TablesDetails from './table/table'
import { Invoice, InvoiceStatus, InvoiceType } from '../../../../types/invoice'
import { downloadInvoiceAttachment } from '../../../../services/invoice-attachment'
import { useNavigate } from 'react-router-dom'
import { formatNumber } from '../../../../lib/format-number'

interface InvoiceContentProps {
  invoice: Invoice | null
}

const InvoiceContent = ({ invoice }: InvoiceContentProps) => {
  const navigate = useNavigate();
  const onViewAttachment = async (id?: number) => {
    if (!id) {
      enqueueSnackbar('Could not open file', { variant: 'error' })
      return;
    }
    downloadInvoiceAttachment(id)
      .then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/pdf' })
        const url = window.URL.createObjectURL(blob)
        window.open(url)
      })
      .catch((err) => {
        enqueueSnackbar('Could not open file', { variant: 'error' })
      })
  }

  const onDowloadAttachment = async (id?: number) => {
    if (!id) {
      enqueueSnackbar('Could not download file', { variant: 'error' })
      return;
    }
    downloadInvoiceAttachment(id)
      .then((buffer) => {
        const invoiceAttachement = invoice?.invoiceAttachments?.find(
          (attachment: any) => attachment.ID_INVOICE_ANEXO === id
        )

        if (invoiceAttachement) {
          const blob = new Blob([buffer], { type: 'application/pdf' })
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = invoiceAttachement.NOME_ARQUIVO
          a.click()
        }
      })
      .catch((err) => {
        enqueueSnackbar('Could not download file', { variant: 'error' })
      })
  }

  const handleClickEdit = (event: React.MouseEvent<HTMLButtonElement>) => {
    const path = `/invoice/${invoice?.ID_INVOICE}`;
    return (event.ctrlKey || event.metaKey) ? window.open(path, '_blank') : navigate(path);
  }

  const handleClickBoardingProcess = (event: React.MouseEvent<HTMLButtonElement>) => {
    const path = `/boarding-process/${invoice?.ID_PROCESSO_EMBARQUE}`;
    return (event.ctrlKey || event.metaKey) ? window.open(path, '_blank') : navigate(path);
  }

  const handleClickShipmentAuthorization = (event: React.MouseEvent<HTMLButtonElement>) => {
    const path = `/shipmentAuthorization/${invoice?.ID_AUTORIZACAO_EMBARQUE}`;
    return (event.ctrlKey || event.metaKey) ? window.open(path, '_blank') : navigate(path);
  }

  const showShipmentAuthorization = useMemo(() => {
    return invoice?.ID_AUTORIZACAO_EMBARQUE !== null
  }, [invoice?.ID_AUTORIZACAO_EMBARQUE])

  const showProcessIcon = useMemo(() => {
    if (invoice) return invoice?.ID_PROCESSO_EMBARQUE !== null;
    return false;
  }, [invoice])

  const disableShipmentAndProcessIcons = useMemo(() => {
    return invoice?.ID_TIPO_INVOICE === InvoiceType.INBOUND_DOMESTIC
  }, [invoice])

  const showEditButton = useMemo(() => {
    if (invoice) {
      const allowedStatuses = [
        InvoiceStatus.REGISTERED_INVOICE,
        InvoiceStatus.PENDING,
        InvoiceStatus.WAITING_SISTER_INVOICE,
        InvoiceStatus.PENDING_SHIPMENT_AUTHORIZATION,
      ]
      return allowedStatuses.includes(invoice?.ID_STATUS_INVOICE)
    }

    return false;
  }, [invoice])

  const formatDate = (dateString: string) => {
    if (!dateString) return ''
    const [year, month, day] = dateString.split('T')[0].split('-')
    return `${month}/${day}/${year}`
  }

  const formatDateTime = (dateString: string) => {
    if (!dateString) return '';
    const [datePart, timePart] = dateString.split('T');
    const [year, month, day] = datePart.split('-');
    const formattedTime = timePart ? timePart.split('.')[0] : '';
    return `${month}/${day}/${year} ${formattedTime}`;
  };

  const tableDetails = useMemo(() => {
    const internationlTypes = ['Inbound - International', 'Triage - International'];
    
    const internationalDetails = [
      {
        value: invoice ? formatDate(invoice.DATA_INVOICE as unknown as string) : "",
        label: 'Invoice Date',
      },
      {
        value: invoice?.NUM_PESO_BRUTO ? formatNumber(invoice?.NUM_PESO_BRUTO, 6) : '',
        label: 'Gross Weight (kg)',
      },
      {
        value: invoice?.parceiroAgenteCarga ? `${invoice?.parceiroAgenteCarga?.NUM_MFIR} - ${invoice?.parceiroAgenteCarga?.NOME_PARCEIRO}` : null,
        label: 'Freight Forwarder',
      },
      {
        value: invoice?.ID_MODAL,
        label: 'Transportation Mode',
      },
      {
        value: invoice?.parceiroBroker?.NOME_PARCEIRO,
        label: 'Broker',
      },
      {
        value: invoice?.parceiroDomesticCarrier?.NOME_PARCEIRO,
        label: 'Carrier',
      },
      {
        value: invoice?.DATA_ENTRADA_PORTARIA ? formatDate((invoice?.DATA_ENTRADA_PORTARIA as unknown as string).split('T')[0]) : "",
        label: 'Dock Date',
      },
      {
        value: invoice?.customsRegime?.DESCRICAO_REGIME,
        label: 'Custom Regime'
      },
      {
        value: <div style={{ minHeight: '20px' }}></div>,
        label: ' ',
      }
    ]

    const domesticDetails = [
      {
        value: invoice ? formatDate(invoice.DATA_INVOICE as unknown as string) : "",
        label: 'Invoice Date',
      },
      {
        value: invoice?.NUM_PESO_BRUTO ? formatNumber(invoice?.NUM_PESO_BRUTO) : '',
        label: 'Gross Weight',
      },
      {
        value: invoice?.parceiroDomesticCarrier?.NOME_PARCEIRO,
        label: 'Carrier',
      },
      {
        value: invoice?.DATA_ENTRADA_PORTARIA ? formatDateTime(invoice.DATA_ENTRADA_PORTARIA as unknown as string) : '',
        label: 'Conference Date',
      },
      {
        value: <div style={{ minHeight: '20px' }}></div>,
        label: ' ',
      },
    ]
    return internationlTypes.includes(invoice?.ID_TIPO_INVOICE ?? 'Inbound - International') ? internationalDetails : domesticDetails
  }, [invoice])

  const tableDetails1 = useMemo(() => {
    const internationlTypes = ['Inbound - International', 'Outbound - International']

    const domesticDetails = [
      {
        value: invoice?.DATA_RECEBIMENTO ? formatDate((invoice.DATA_RECEBIMENTO as unknown as string).split('T')[0]) : "",
        label: 'Received Date',
      },
      {
        value: invoice?.DATA_REGISTRO ? formatDate((invoice.DATA_REGISTRO as unknown as string).split('T')[0]) : "",
        label: 'Registered',
      },
      {
        value: invoice?.serviceLevel?.DSC_PRIORIDADE,
        label: 'Service Level',
      },
      {
        value: `${invoice?.currency?.SIGLA_MOEDA} - ${invoice?.currency?.DSC_MOEDA}`,
        label: "Currency",
      },
      {
        value: invoice?.incoterms?.SIGLA,
        label: 'Incoterms',
      },
      {
        value: invoice?.incotermsComplement?.DSC_INCOTERMS_COMPL,
        label: 'Incoterms Complement',
      },
      {
        value: invoice?.NUM_TRACKING,
        label: 'Tracking Number',
      }
    ]

    const internationalDetails = [
      ...domesticDetails,
      {
        value: invoice?.paisOrigem?.SIGLA_PAIS,
        label: 'Country of Source',
      },
    ]

    return internationlTypes.includes(invoice?.ID_TIPO_INVOICE ?? 'Inbound - International') ? internationalDetails : domesticDetails
  }, [invoice])

  const attachementTableData = useMemo(() => {
    return (invoice?.invoiceAttachments || []).map((attachment: any) => ({
      id: attachment.ID_INVOICE_ANEXO,
      label: attachment.ID_TIPO_ANEXO,
      value: attachment.NOME_ARQUIVO,
    }))
  }, [invoice?.invoiceAttachments])

  return (
    <Box>
      <Grid container justifyContent='space-between' alignItems='flex-start'>
        <div
          style={{
            width: 450,
          }}
        >
          <TablesDetails data={tableDetails} />
        </div>
        <div
          style={{
            width: 450,
          }}
        >
          <TablesDetails data={tableDetails1} />
        </div>
      </Grid>

      <Grid
        style={{
          marginTop: 60,
          marginBottom: 20,
          borderTop: '1px solid rgba(0, 0, 0, 0.6)',
        }}
        container
        alignItems='center'
        justifyContent='space-between'
      >
        <Grid xs={3} item style={{
          paddingTop: '20px',
          paddingBottom: '20px',
        }}>
          <Typography sx={{ fontWeight: 'bold' }} variant='body2'>
            Ship to
          </Typography>
          <Typography sx={{ fontWeight: 'bold' }} variant='body1'>
            {invoice?.NOME_IMPORT}
          </Typography>
          <Typography variant='body1'>
            {invoice?.DSC_ENDERECO1_IMPORT}
          </Typography>
          <Typography variant='body1'>
            {invoice?.DSC_CIDADE_IMPORT} - {invoice?.DSC_ESTADO_IMPORT}
          </Typography>
          <Typography variant='body1'>
            {invoice?.CEP_IMPORT}
          </Typography>
          <Typography variant='body1'>
            {invoice?.SIGLA_PAIS_IMPORT}
          </Typography>
        </Grid>
        <Grid xs={6} item style={{
          borderLeft: '1px solid rgba(0, 0, 0, 0.6)',
          borderRight: '1px solid rgba(0, 0, 0, 0.6)',
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingTop: '20px',
          paddingBottom: '20px',
        }}>
          <div>
            <Typography sx={{ fontWeight: 'bold' }} variant='body2'>
              Attachment File Type
            </Typography>
            <TablesDetails
              data={attachementTableData}
              type='attachments'
              onView={onViewAttachment}
              onDownload={onDowloadAttachment}
            />
          </div>
        </Grid>
        <Grid xs={3} item container justifyContent='right' alignContent='right' style={{
          paddingTop: '20px',
          paddingBottom: '20px',
        }}>
          <>
            {!disableShipmentAndProcessIcons && showProcessIcon && (
              <Tooltip title='Process' placement='top'>
                <IconButton
                  style={{
                    marginRight: 8,
                    borderRadius: '4px',
                    border: '1px solid #1976d2',
                  }}
                  aria-label='Process'
                  size='small'
                  onClick={handleClickBoardingProcess}
                >
                  <Inventory style={{ color: '#1976d2' }} />
                </IconButton>
              </Tooltip>
            )}
            {!disableShipmentAndProcessIcons && showShipmentAuthorization && (
              <Tooltip title='Shipment Authorization' placement='top'>
                <IconButton
                  style={{ borderRadius: '4px', border: '1px solid #1976d2' }}
                  aria-label='Shipment Authorization'
                  size='small'
                  onClick={handleClickShipmentAuthorization}
                >
                  <Folder style={{ color: '#1976d2' }} />
                </IconButton>
              </Tooltip>
            )}
            {showEditButton && (
              <Tooltip title='Edit' placement='top'>
                <IconButton
                  style={{
                    borderRadius: '4px',
                    border: '1px solid #1976d2',
                    marginLeft: 8,
                  }}
                  aria-label='Edit'
                  size='small'
                  onClick={handleClickEdit}
                >
                  <BorderColorSharp style={{ color: '#1976d2' }} />
                </IconButton>
              </Tooltip>
            )}
          </>
        </Grid>
      </Grid>


    </Box>
  )
}

export default InvoiceContent
