import { Autocomplete, Box, Button, Grid, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ChangeEvent, useState } from "react";
import InputUpload from "../../../../../components/Input/InputUpload";
import { downloadFile, downloadFileFromUrl, viewFileInNewTabFromUrl, viewFileInNewTab } from "../../../../utils/file";
import ConfirmDialog, { ConfirmDialogType } from "../../../../../components/Dialog/ConfirmDialog";
import { ComboBoxType } from "../../../../../types/general";
import { Controller, UseFormRegister } from "react-hook-form";
import { Invoice } from "../../../../../types/invoice";
import { enqueueSnackbar } from "notistack";
import { downloadInvoiceAttachment } from "../../../../../services/invoice-attachment";

interface InvoiceAttachmentsProps {
  watch: (key: string) => {},
  setValue: (key: string, value: Partial<AttachmentType>[]) => void,
  setError: (key: string, options: { type: string, message: string }) => void,
  clearErrors: (key: string) => void,
  invoiceAttachmentTypes: ComboBoxType[],
  formErrors: { [key: string]: string };
  control: any;
  register: UseFormRegister<Invoice>;
}

type AttachmentType = {
  FILE: File;
  ID_TIPO_ANEXO: string;
  ID_INVOICE_ANEXO?: number;
  DSC_PATH?: string;
  NOME_ARQUIVO?: string;
}

const key = 'invoiceAttachments';

export default function InvoiceAttachments({
  watch, 
  setValue,
  setError,
  clearErrors,
  invoiceAttachmentTypes,
  formErrors,
  register,
  control
}: InvoiceAttachmentsProps) {
  const [selectedAttachmentType, setSelectedAttachmentType] = useState('');
  const [dialogMeta, setDialogMeta] = useState<ConfirmDialogType>({ open: false } as ConfirmDialogType);

  const attachments: AttachmentType[] = watch(key) as Array<AttachmentType> || [];

  const addInput = (event: ChangeEvent<HTMLInputElement>) => {
    if (!selectedAttachmentType) {
      setError('attachmentTypes', { type: 'custom', message: 'You must select an attachment type' });
      return;
    }

    if (!!event.target?.files?.length) {
      const files = event.target.files;
      if (files) {
        const fileToAdd = files[0];

        if (fileToAdd.type !== "application/pdf") {
          return enqueueSnackbar("File type is not allowed. Please select a PDF file", { variant: "error"});
        }

        const existThisAttachType = attachments.find((attachment: AttachmentType) => attachment.ID_TIPO_ANEXO === selectedAttachmentType);

        if (existThisAttachType) {
          setDialogMeta({
            open: true,
            message: `A file of type ${selectedAttachmentType} already exists. Do you want to replace it?`,
            callbackOnConfirm: () => updateFile(fileToAdd, existThisAttachType?.ID_INVOICE_ANEXO!),
            callbackOnCancel: () => { setDialogMeta({ open: false }) }
          });

          return;
        }

        addFile(fileToAdd);
      }
    }
  }

  const addFile = (fileToAdd: File) => {
    attachments.push({
      FILE: fileToAdd,
      ID_TIPO_ANEXO: selectedAttachmentType,
    });

    setValue(key, attachments);
    clearErrors('invoiceAttachments');
    clearErrors('attachmentTypes');
  }

  const updateFile = (fileToAdd: File, ID_INVOICE_ANEXO: number) => {
    const filteredAttachment = attachments.filter((attachment: AttachmentType) => attachment.ID_TIPO_ANEXO !== selectedAttachmentType);

    filteredAttachment.push({
      FILE: fileToAdd,
      ID_TIPO_ANEXO: selectedAttachmentType,
      ID_INVOICE_ANEXO
    });

    setValue(key, filteredAttachment);
    setDialogMeta({
      open: false
    })
  }

  const removeInput = (index: number, attachment: AttachmentType): void => {
    attachments.splice(index, 1);

    setValue(key, attachments);
  
    if (attachment.ID_INVOICE_ANEXO) {
      setValue('invoiceAttachmentsToDelete', [{
        ID_INVOICE_ANEXO: attachment.ID_INVOICE_ANEXO, 
        ID_TIPO_ANEXO: attachment.ID_TIPO_ANEXO,
        DSC_PATH: attachment.DSC_PATH
      }]);  
    }
  }

  const onViewAttachment = async (id?: number) => {
    if (!id) {
      enqueueSnackbar('Could not open file', { variant: 'error' })
      return;
    }
    downloadInvoiceAttachment(id)
      .then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/pdf' })
        const url = window.URL.createObjectURL(blob)
        window.open(url)
      })
      .catch((err) => {
        enqueueSnackbar('Could not open file', { variant: 'error' })
      })
  }

  const onDowloadAttachment = async (invoiceAttachement?: AttachmentType) => {
    if (!invoiceAttachement?.ID_INVOICE_ANEXO) {
      enqueueSnackbar('Could not download file', { variant: 'error' })
      return;
    }
    downloadInvoiceAttachment(invoiceAttachement.ID_INVOICE_ANEXO)
      .then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/pdf' })
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = invoiceAttachement.NOME_ARQUIVO!
        a.click();
      })
      .catch((err) => {
        enqueueSnackbar('Could not download file', { variant: 'error' })
      })
  }

  return (
    <Box>
      <ConfirmDialog dialogMeta={dialogMeta} />
      <Grid container marginY={2} spacing={2}>
        <Grid item xs={3}>
          <Controller
            name="ID_TIPO_ANEXO"
            control={control}
            render={({ field: { value, ref, onChange, ...field }  }) => ( 
              <>
                {
                  invoiceAttachmentTypes && <Autocomplete
                    options={invoiceAttachmentTypes}
                    getOptionLabel={(option) => option.description}
                    value={value}
                    renderInput={(params) => (
                      <>
                        <TextField
                          {...params}
                          InputLabelProps={{ shrink: true }}
                          label="Attach type"
                          size="small"
                          variant="outlined"
                          error={!!formErrors.invoiceAttachments}
                          {...register("ID_TIPO_ANEXO")}
                        />
                      </>
                    )}
                    onChange={(_, data: ComboBoxType) => {
                      setSelectedAttachmentType(data?.description);
                      onChange(data?.description);
                      if (data?.description) {
                        clearErrors('attachmentTypes');
                      }
                    }}
                  />
                }
              </>
            )}
          />
        </Grid>
        <Grid item xs={5}>
          <InputUpload
            onInput={addInput}
            accept="application/pdf"
            onDrop={(files: FileList) => {
              addInput({ target: { files } } as ChangeEvent<HTMLInputElement>);
            }}
          />
        </Grid>
      </Grid>

      <Grid container marginY={2} spacing={2}>
        {
          (attachments && attachments.length > 0) &&
          <Grid item xs={8}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell scope="row">
                    Attachment Files Type
                  </TableCell>
                  <TableCell scope="row">
                    File Name
                  </TableCell>
                  <TableCell scope="row" />
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  attachments.map((attachment: AttachmentType, index: number) => (
                    <TableRow
                      key={`invoice-table-${attachment.ID_TIPO_ANEXO}`}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 }}}
                    >
                      <TableCell scope="row">
                        <Typography fontWeight={600} fontSize={14}>
                          {attachment.ID_TIPO_ANEXO}
                        </Typography>
                      </TableCell>
                      <TableCell scope="row">
                        {attachment.FILE?.name || attachment.NOME_ARQUIVO}
                      </TableCell>
                      <TableCell sx={{ minWidth: '120px' }} align="right">
                        <Button
                          sx={{ minWidth: 0 }}
                          color="primary"
                          onClick={() => attachment.FILE ? viewFileInNewTab(attachment.FILE) : onViewAttachment(attachment.ID_INVOICE_ANEXO)}
                          disableRipple={true}
                        >
                          <VisibilityIcon />
                        </Button>
                        <Button
                          sx={{ minWidth: 0, color: 'rgba(0, 0, 0, 0.4)' }}
                          color="primary"
                          onClick={() => attachment.FILE ? downloadFile(attachment.FILE) : onDowloadAttachment(attachment)}
                          disableRipple={true}
                        >
                          <FileDownloadIcon />
                        </Button>
                        <Button
                          sx={{ minWidth: 0 }}
                          color="error"
                          onClick={() => removeInput(index, attachment)}
                        >
                          <DeleteIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </Grid>
        }
      </Grid>
    </Box>
  )
}
