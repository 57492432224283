import { useEffect, useState } from "react";
import { api } from "../../lib/api";
import { getToken } from "../../storage/auth";
import { Warehouse, WarehouseCreationData, WarehouseDetails } from "../../types/warehouse";

const entity = "warehouses";

export const findReadyToShip = async (findParams?: {
  ID_CENTRO: number,
  ID_CENTRO_CONSOLIDADOR: number,
  ID_MODAL: string,
  ID_FRETE_INCO: string,
  NUM_WAREHOUSE: string;
  group: boolean;
}): Promise<Warehouse[]> => {
  const token = await getToken();
  const { data } = await api.put(`/${entity}/search/invoices`, findParams, {
    headers: { authorization: `Bearer ${token}` },
  });
  
  return data;
};

export const useFind = (
  idInvoice?: number | null,
  invoiceIdCenter?: number | null,
  consolidationCenter?: number | null
): {
  warehouse: Warehouse | undefined,
  setRefetch: React.Dispatch<React.SetStateAction<boolean>>,
  setNumWarehouse: React.Dispatch<React.SetStateAction<string>>,
  fetchedByNumber: boolean,
  setFetchedByNumber: React.Dispatch<React.SetStateAction<boolean>>,
  setWarehouse: React.Dispatch<React.SetStateAction<Warehouse>>,
  setIdCentroConsolidador: React.Dispatch<React.SetStateAction<string | null>>,
} => {
  const token = getToken();
  const [warehouse, setWarehouse] = useState<Warehouse|null|undefined>({} as Warehouse);
  const [refetch, setRefetch] = useState<boolean>(true);
  const [numWarehouse, setNumWarehouse] = useState<string>("");
  const [fetchedByNumber, setFetchedByNumber] = useState<boolean>(false);
  const [idCentroConsolidador, setIdCentroConsolidador] = useState<string | null>(null);

  useEffect(() => {
    (() => {
      if (numWarehouse !== "") {
        api
          .get(`/${entity}/findByNum/${numWarehouse}`, {
            headers: { authorization: `Bearer ${token}` },
            params: { invoiceIdCenter, consolidationCenter: (typeof consolidationCenter === 'number' || consolidationCenter) ? consolidationCenter : null  }
          })
          .then((response: any) => {
            const { data } = response;
            data.object.QUANT_PESO_BRUTO = Number(data.object.QUANT_PESO_BRUTO);
            data.object.QUANT_PESO_CUBICO = String(Number(data.object.QUANT_PESO_CUBICO));
            data.object.QUANT_PESO_TAXADO = String(Number(data.object.QUANT_PESO_TAXADO));
            setWarehouse(data.object);
            setFetchedByNumber(true);
          })
      }
    })()
  }, [numWarehouse, idCentroConsolidador]);

  useEffect(() => {
    (() => {
      if (idInvoice) {
        api
          .get(`/${entity}/findByInvoiceId/${idInvoice}`, {
            headers: { authorization: `Bearer ${token}` },
          })
          .then((response: any) => {
            const { data } = response;
            data.object.QUANT_PESO_BRUTO = Number(data.object.QUANT_PESO_BRUTO);
            data.object.QUANT_PESO_CUBICO = String(Number(data.object.QUANT_PESO_CUBICO));
            data.object.QUANT_PESO_TAXADO = String(Number(data.object.QUANT_PESO_TAXADO));
            setWarehouse(data.object);
            setRefetch(false);
          })
      }
    })()
  }, [idInvoice, refetch]);

  return { 
    warehouse,
    setRefetch, 
    setNumWarehouse, 
    fetchedByNumber, 
    setFetchedByNumber,
    setWarehouse,
    setIdCentroConsolidador 
  };
};

export const findDetails = async (idWarehouse?: number): Promise<WarehouseDetails | undefined> => {
  if (!idWarehouse) {
    return {} as WarehouseDetails;
  }
  const token = getToken();

  const response = await api
    .get(`/${entity}/findDetails/${idWarehouse}`, {
      headers: { authorization: `Bearer ${token}` },
    })

  return response.data.object;
}

export const useFindDetails = (
  idWarehouse: number | null
): {
  warehouseDetails: WarehouseDetails | undefined,
  setWarehouseDetails: React.Dispatch<React.SetStateAction<WarehouseDetails>>
  setRefetch: React.Dispatch<React.SetStateAction<boolean>>
} => {
  const token = getToken();
  const [warehouseDetails, setWarehouseDetails] = useState<WarehouseDetails>({} as WarehouseDetails);
  const [refetch, setRefetch] = useState<boolean>(true);

  useEffect(() => {
    (() => {
      if (refetch && idWarehouse) {
        api
          .get(`/${entity}/findDetails/${idWarehouse}`, {
            headers: { authorization: `Bearer ${token}` },
          })
          .then((response: any) => {
            const { data } = response;
            setWarehouseDetails(data.object);
            setRefetch(false);
          })
      }
    })()
  }, [idWarehouse, refetch]);

  return { warehouseDetails, setWarehouseDetails, setRefetch };
};

export const save = async (warehouse: WarehouseCreationData, idInvoice?: number): Promise<{ success: boolean, message: string[] }> => {
  const token = getToken();

  const { data } = await api.post(`/${entity}`, { idInvoice, warehouse }, {
    headers: { authorization: `Bearer ${token}` },
  });

  return { success: data.success, message: data.message };
}

export const findWarehouseByWrOrInvoiceNumber = async (
  warehouseOrInvoiceNum: string, 
  idCentro: number,
  siglaCentro: string,
  idFreightForwarder: number,
  dataETD: string,
  idServiceLevel: number,
  idBroker: number,
  idModal: number,
  idCurrency: number,
  idCustomRegime: number,
) => {
  const token = getToken();
  const { data } = await api.get(`/${entity}/findByWrOrInvoiceNum`, {
    params: {
      warehouseOrInvoiceNum,
      idCentro,
      siglaCentro,
      idFreightForwarder,
      dataETD,
      idServiceLevel,
      idBroker,
      idModal,
      idCurrency,
      idCustomRegime,
    },
    headers: { authorization: `Bearer ${token}` },
  });
  
  return data;
}
