import { Card, Grid, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import CardPipeline from "../../../components/CardPipeline";
import { getGraphics, getStatuses, getTotals } from "../../../services/dashboard";
import CardGraphic from "../../../components/CardGraphic";
import { GraphicItem, Graphics, SearchParams, Totals } from "../../../types/dashboard";
import { Invoice, InvoiceStatusInAndOutSLA, openStatuses } from "../../../types/invoice";
import { Company } from "../../../types/company";
import { Center } from "../../../types/center";
import { getInvoices, getInvoiceTypes, InvoiceType } from "../../../services/invoice";
import { getInvoiceCompanies } from "../../../services/companies";
import { getCenters } from "../../../services/centers";
import { getServiceLevels } from "../../../services/serviceLevel";
import { ComboBox } from "../../../types/comboBox";
import LoadingOverlay from "../../../components/LoadingOverlay";
import { enqueueSnackbar } from "notistack";
import DashboardInvoicesTable from "./DashboardInvoicesTable";
import { useSetStateWrapper } from "../../utils/wrappers";
import { useSearchParams } from "react-router-dom";
import { useReferredState } from "../../utils/states";

const iconFilter = (
  <svg width="10" height="11" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.7279 16.1667C6.45637 16.1667 6.22893 16.0747 6.04557 15.8907C5.86157 15.7073 5.76957 15.4799 5.76957 15.2083V9.45834L0.211231 2.36668C-0.028352 2.04723 -0.0641297 1.71182 0.103898 1.36043C0.271287 1.00904 0.56262 0.833344 0.977898 0.833344H14.3946C14.8098 0.833344 15.1015 1.00904 15.2695 1.36043C15.4369 1.71182 15.4008 2.04723 15.1612 2.36668L9.6029 9.45834V15.2083C9.6029 15.4799 9.51122 15.7073 9.32786 15.8907C9.14386 16.0747 8.91609 16.1667 8.64456 16.1667H6.7279Z" fill="#0045AA" />
  </svg>
)

const InitialStatuses: InvoiceStatusInAndOutSLA = openStatuses
  .reduce((prev, curr: string) => {
    prev[curr] = {
      IN_SLA: 0,
      OUT_SLA: 0
    };
    return prev;
  }, {} as InvoiceStatusInAndOutSLA);

const InitialTotals: Totals = {
  AOG: 0,
  ROUTINE: 0,
  CRITICAL: 0,
  TOTAL: 0,
}

const InitSearchParams: SearchParams = {
  ID_STATUS_INVOICE: null,
  ID_TIPO_INVOICE: null,
  INTERCOMPANY: undefined,
  WAITING_PO: undefined,
  SEARCH_TEXT: '',
  SEARCH_TEXT_GENERAL: '',
  SEARCH_FIELD: null,
  page: 1,
  limit: 5
}

const InitialGraphics: Graphics = {
  transportationMode: [],
  serviceLevel: [],
  origin: [],
  destination: [],
}

const parseToURLSearchParams = (data: object) =>
  new URLSearchParams(
    Object.entries(data)
      .filter(([, value]) => !!value)
      .map(([key, value]) => [key, JSON.stringify(value)])
  );

const parseToSearchParams = (data: URLSearchParams): SearchParams => {
  try {
    return Object.fromEntries([...data.entries()].map(([key, value]) => [key, JSON.parse(value)]));
  } catch {
    return InitSearchParams
  }
}

const orderByFields = {
  NUM_INVOICE: 'invoice.NUM_INVOICE',
  ID_MODAL: 'invoice.ID_MODAL',
  NUM_TRACKING: 'invoice.NUM_TRACKING',
  DSC_PRIORIDADE: 'serviceLevel.DSC_PRIORIDADE',
  'Freight Forwarder': 'parceiroAgenteCarga.NOME_PARCEIRO',
  'Incoterms': 'incoterms.SIGLA',
  'Incoterms Complement': 'incotermsComplement.DSC_INCOTERMS_COMPL',
  ID_AUTORIZACAO_EMBARQUE: 'invoice.ID_AUTORIZACAO_EMBARQUE',
  ID_PO: 'po.NUMERO_PO',
  ID_WAREHOUSE: 'warehouse.NUM_WAREHOUSE',
  CODIGO_PROCESSO: 'processoEmbarque.CODIGO_PROCESSO',
  CODIGO_HOUSE_HAWB: 'processoEmbarque.CODIGO_HOUSE_HAWB',
};

export default function Dashboard() {
  const [urlSearchParams, setURLSearchParams] = useSearchParams();
  const oldSizeUrlSearchParams = useRef(urlSearchParams.size);
  const [statuses, setStatuses] = useState<InvoiceStatusInAndOutSLA>(InitialStatuses);
  const [graphics, setGraphics] = useState<Graphics>(InitialGraphics);
  const [outOfTarget, setOutOfTarget] = useState<number>(0);
  const [totals, setTotals] = useState<Totals>(InitialTotals);
  const [data, setData] = useState<Invoice[]>([]);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [centers, setCenters] = useState<Center[]>([]);
  const [serviceLevels, setServiceLevels] = useState<ComboBox[]>([]);
  const [invoiceTypes, setInvoiceTypes] = useState<InvoiceType[]>([]);
  const [searchParams, setSearchParams, refSearchParams] = useReferredState<SearchParams>({
    ...InitSearchParams,
    ...parseToSearchParams(urlSearchParams)
  });
  const initialPageModel = (searchParams.page) || 0;
  const [isHydrated, setIsHydrated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<number | null>(null);
  const [paginationModel, setPaginationModel] = useState({
    page: initialPageModel === 1 ? initialPageModel - 1 : initialPageModel,
    pageSize: searchParams.limit || 5,
  });
  const [total, setTotal] = useState<number>(0);
  const prevSearchParamsRef = useRef<SearchParams | undefined>(undefined);

  const getSearchParamsRef = () => refSearchParams.current;

  const setNewSearchParams = useSetStateWrapper(setSearchParams, (newSearchParams) => {
    if (!newSearchParams.ID_EMPRESA || newSearchParams.ID_EMPRESA?.length < 1) return;
    if (!newSearchParams.ID_EMPRESA || newSearchParams.ID_EMPRESA?.length === 1 && !newSearchParams.ID_CENTRO?.length) return;
    setURLSearchParams(parseToURLSearchParams(newSearchParams));
  });

  useEffect(() => {
    const loadCombos = async () => {
      setIsLoading(true);
      try {
        const [companiesResponse, invoiceTypesResponse, serviceLevelsResponse] = await Promise.all([
          getInvoiceCompanies(),
          getInvoiceTypes(),
          getServiceLevels()
        ])
        setCompanies(companiesResponse);
        setInvoiceTypes(invoiceTypesResponse);
        setServiceLevels(serviceLevelsResponse);
        const firstCompany = companiesResponse[0];
        if (companiesResponse.length) {

          const hasStoredFilters = !!urlSearchParams.size;

          if (searchParams.ID_EMPRESA && searchParams.ID_EMPRESA.length > 1) return;
          const centersResponse = await getCenters(hasStoredFilters ? searchParams.ID_EMPRESA! : [+firstCompany?.ID_EMPRESA!]);
          setCenters(centersResponse);
          const firstCenter = centersResponse[0];

          if (!hasStoredFilters && firstCompany)
            setNewSearchParams({
              ...searchParams,
              ID_EMPRESA: firstCompany?.ID_EMPRESA ? [+firstCompany.ID_EMPRESA] : [],
              ID_CENTRO: firstCenter?.ID_CENTRO ? [+firstCenter.ID_CENTRO] : []
            });
        }
      } catch (error) {
        enqueueSnackbar("Could not load combos", { variant: "error" });
      } finally {
        setIsHydrated(true);
        setIsLoading(false);
      }
    }

    loadCombos();
  }, []);

  const fetchData = async () => {
    let searchParams = { ...getSearchParamsRef() };
    const hasInvalidParams = [
      !searchParams.ID_EMPRESA || searchParams.ID_EMPRESA?.length < 1,
      !searchParams.ID_EMPRESA || searchParams.ID_EMPRESA?.length === 1 && !searchParams.ID_CENTRO?.length
    ].some(Boolean);

    if (hasInvalidParams) searchParams = { ...searchParams, ...parseToSearchParams(urlSearchParams) };
    const prevSearchParams = prevSearchParamsRef.current;

    if (isHydrated) {
      prevSearchParamsRef.current = searchParams;

      if (
        prevSearchParams?.ID_EMPRESA !== searchParams.ID_EMPRESA
        || prevSearchParams?.ID_CENTRO !== searchParams.ID_CENTRO
      ) {
        handleGetStatuses();
        handleGetTotals();
        handleGetGraphicsData();
      }

      loadData();
    }
  }

  useEffect(() => {
    fetchData();
  }, [isFetching, isHydrated]);

  const loadData = async () => {
    const searchParams = getSearchParamsRef();
    setIsLoading(true);
    try {
      const { ORDER_FIELD, ORDER_SORT } = searchParams;
      const { total, invoices } = await getInvoices({
        ...searchParams,
        ...(ORDER_FIELD && ORDER_SORT ? {
          ORDER_FIELD: orderByFields[ORDER_FIELD as keyof typeof orderByFields],
          ORDER_SORT: ORDER_SORT.toUpperCase() as SearchParams['ORDER_SORT']
        } : null)
      });
      setData(invoices);
      setTotal(total);
    } catch (error) {
      enqueueSnackbar("Could not load invoices", { variant: "error" });
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const loadCenters = async (ID_EMPRESA: number[]) => {
    const centersResponse = await getCenters(ID_EMPRESA);
    setCenters(centersResponse);
    const firstCenter = centersResponse[0];
    setNewSearchParams((searchParams) => ({
      ...searchParams,
      ID_EMPRESA,
      ID_CENTRO: firstCenter?.ID_CENTRO ? [firstCenter?.ID_CENTRO] : []
    }));
    return centersResponse;
  }

  const handleGetStatuses = async () => {
    const searchParams = getSearchParamsRef();
    if (searchParams.ID_CENTRO || searchParams.ID_EMPRESA) {
      const response = await getStatuses(searchParams.ID_CENTRO!, searchParams.ID_EMPRESA!);
      setOutOfTarget(response.total.OUT_SLA);
      setStatuses(response.statuses);
    } else {
      setOutOfTarget(0);
      setStatuses(InitialStatuses);
    }
  }

  const handleGetTotals = async () => {
    const searchParams = getSearchParamsRef();
    if (searchParams.ID_CENTRO || searchParams.ID_EMPRESA) {
      const response = await getTotals(searchParams.ID_CENTRO!, searchParams.ID_EMPRESA!);
      setTotals(response);
    } else {
      setTotals(InitialTotals);
    }
  }

  const handleSelectPriority = (priority: string) => {
    if (String(searchParams.ID_PRIORIDADE) === priority) {
      delete searchParams.ID_PRIORIDADE;
      setNewSearchParams({ ...searchParams });
    } else {
      setNewSearchParams({ ...searchParams, ID_PRIORIDADE: Number(priority) });
      setIsFetching(Math.random());
    }
  }

  const handleGetGraphicsData = async () => {
    const searchParams = getSearchParamsRef();
    if (searchParams.ID_CENTRO || searchParams.ID_EMPRESA) {
      const response = await getGraphics(searchParams.ID_CENTRO!, searchParams.ID_EMPRESA!);

      setGraphics(response);
    }
  }

  const handleFormatGraphicData = (data: GraphicItem[]) => {
    if (data && data?.length <= 0) {
      return [];
    }

    return data?.map((item: GraphicItem) => {
      const key = Object.keys(item)[0];
      return {
        label: key,
        value: item[key].percentage,
        total: item[key].total
      }
    });
  }

  const handleChangeSearchParam = (key: string) => async (e: any): Promise<SearchParams | undefined> => {
    if (!isHydrated) return;
    if (['INTERCOMPANY', 'WAITING_PO'].includes(key)) {
      if (e.target.checked)
        searchParams[key as 'INTERCOMPANY' | 'WAITING_PO'] = e.target.checked
      else delete searchParams[key as keyof SearchParams];
      return { ...searchParams };
    } else if (key === 'ID_EMPRESA') {
      if (e.target.value.length > 1) delete searchParams.ID_CENTRO;
      else if (e.target.value.length === 1) {
        const centers = await loadCenters(e.target.value);
        return { ...searchParams, [key]: e.target.value, ID_CENTRO: (centers?.map(({ ID_CENTRO }) => ID_CENTRO!) ?? []).slice(0, 1) };
      } else return { ...searchParams, [key]: e.target.value, ID_CENTRO: [] };
      return { ...searchParams, [key]: e.target.value };
    } else if (key === 'ID_CENTRO') return ({ ...searchParams, [key]: e.target.value });
    else if (key === 'ID_STATUS_INVOICE' && searchParams.ID_STATUS_INVOICE !== e.target.value) {
      delete searchParams.IN_SLA;
      return ({ ...searchParams, [key]: e.target.value });
    } else return ({ ...searchParams, [key]: e.target.value });
  }

  const onChangeSearchParam = (key: string) => async (e: any) => {
    const newSearchParams = await handleChangeSearchParam(key)(e);
    if (!newSearchParams) return;

    setNewSearchParams(newSearchParams);

    const hasInvalidParams = [
      !newSearchParams.ID_EMPRESA || newSearchParams.ID_EMPRESA?.length < 1,
      !newSearchParams.ID_EMPRESA || newSearchParams.ID_EMPRESA?.length === 1 && !newSearchParams.ID_CENTRO?.length
    ].some(Boolean);
    if (hasInvalidParams) return;

    setIsFetching(Math.random());

  }

  const handleSelectSLA = (status: string, inSla: boolean) => {
    if (inSla === searchParams.IN_SLA && status === searchParams.ID_STATUS_INVOICE) {
      delete searchParams.IN_SLA;
    } else {
      searchParams.IN_SLA = inSla;
    }

    searchParams.ID_STATUS_INVOICE = status;

    setNewSearchParams({ ...searchParams });
  }

  const reset = async () => {
    const [firstCompany] = companies || [];
    const [firstCenter] = centers || [];

    setNewSearchParams({
      ...InitSearchParams,
      ID_EMPRESA: firstCompany?.ID_EMPRESA ? [+firstCompany.ID_EMPRESA] : [],
      ID_CENTRO: firstCenter?.ID_CENTRO ? [+firstCenter.ID_CENTRO] : []
    });

    setPaginationModel({
      page: 0,
      pageSize: 5,
    });

    if (!firstCompany?.ID_EMPRESA) return;
    if (firstCenter?.ID_CENTRO) {
      const [loadedCenter] = await loadCenters([+firstCompany.ID_EMPRESA]);
      if (loadedCenter?.ID_CENTRO) refSearchParams.current.ID_CENTRO = [+loadedCenter.ID_CENTRO];
      fetchData();
      return;
    }
    try {
      const [firstCenter] = await loadCenters([+firstCompany.ID_EMPRESA]);
      refSearchParams.current.ID_CENTRO = [+firstCenter.ID_CENTRO!];
      fetchData();
    } catch {
      setIsLoading(false);
    }
  }

  const validServiceLevels = serviceLevels.filter(serviceLevel => !!totals[serviceLevel.description as keyof Totals])

  useEffect(() => {
    const { current: oldSize } = oldSizeUrlSearchParams;
    if (oldSize > 1 && !urlSearchParams.size) reset();
    oldSizeUrlSearchParams.current = urlSearchParams.size;
  }, [urlSearchParams.size]);

  return (
    <div className='customScroll' id="dashboard">
      <LoadingOverlay isLoading={isLoading} />
      <Grid container spacing={2} marginTop={4}>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <Grid padding={2} container>
              <Grid item xs={12} sm={6} md={6}>
                <Typography color="#707070">PRIORITY</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} textAlign="right">
                <svg width="46" height="42" viewBox="0 0 46 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M25.8234 2.50226C25.5388 2.00651 25.1284 1.59463 24.6337 1.3082C24.139 1.02177 23.5775 0.870941 23.0059 0.870941C22.4342 0.870941 21.8727 1.02177 21.378 1.3082C20.8833 1.59463 20.473 2.00651 20.1884 2.50226L0.474494 36.0449C-0.839381 38.2816 0.736119 41.125 3.29199 41.125H42.7169C45.2727 41.125 46.8511 38.2788 45.5344 36.0449L25.8234 2.50226ZM23.0001 12.375C24.5382 12.375 25.7429 13.7033 25.5876 15.2356L24.5814 25.3183C24.5476 25.7144 24.3663 26.0833 24.0735 26.3522C23.7807 26.6211 23.3977 26.7703 23.0001 26.7703C22.6026 26.7703 22.2195 26.6211 21.9267 26.3522C21.6339 26.0833 21.4527 25.7144 21.4189 25.3183L20.4126 15.2356C20.3765 14.8741 20.4165 14.5089 20.53 14.1637C20.6436 13.8186 20.8282 13.501 21.072 13.2315C21.3157 12.962 21.6133 12.7466 21.9453 12.5991C22.2774 12.4515 22.6367 12.3752 23.0001 12.375ZM23.0059 29.625C23.7684 29.625 24.4996 29.9279 25.0388 30.4671C25.578 31.0062 25.8809 31.7375 25.8809 32.5C25.8809 33.2625 25.578 33.9938 25.0388 34.5329C24.4996 35.0721 23.7684 35.375 23.0059 35.375C22.2434 35.375 21.5121 35.0721 20.9729 34.5329C20.4338 33.9938 20.1309 33.2625 20.1309 32.5C20.1309 31.7375 20.4338 31.0062 20.9729 30.4671C21.5121 29.9279 22.2434 29.625 23.0059 29.625Z" fill="#E60101" />
                </svg>
              </Grid>
            </Grid>

            <div style={{
              display: 'flex',
              flexDirection: 'row',
              padding: '16px',
              gap: '16px',
              height: '100%',
              overflowX: 'auto',
              maxWidth: '100%',
            }}>
              {validServiceLevels.length
                ?
                (serviceLevels.filter(serviceLevel => !!totals[serviceLevel.description as keyof Totals])
                  .map(serviceLevel => (
                    <div key={serviceLevel.id} className="cursor-pointer">
                      <button data-testid="button-select-priority" className="cursor-pointer button-transparent" onClick={() => handleSelectPriority(String(serviceLevel.id))}>
                        {serviceLevel.id === searchParams.ID_PRIORIDADE ? iconFilter : <div style={{ minHeight: '16px' }}></div>}
                        <Typography color="#707070">{serviceLevel.description}</Typography>
                        <Typography color="#545454" fontSize={30} fontWeight={600}>{totals[serviceLevel.description as keyof Totals] || 0}</Typography>
                      </button>
                    </div>
                  )))
                : (
                  <div key='no-service-level' className="cursor-pointer" style={{ width: '100%' }}>
                    <div style={{ minHeight: '30px' }}></div>
                    <Typography
                      style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      color="#545454"
                      fontSize={30}
                      fontWeight={600}
                    >
                      0
                    </Typography>
                  </div>
                )
              }
            </div>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Card style={{ height: "100%" }}>
            <Grid padding={2} container>
              <Grid item xs={12} sm={6} md={6}>
                <Typography color="#707070">OUT OF THE TARGET</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} textAlign="right">
                <svg width="44" height="42" viewBox="0 0 44 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M23.8963 19.2402H19.6364V7.05514H23.8963M23.8963 27.3636H19.6364V23.3019H23.8963M38.8056 0.962616H4.7271C2.3842 0.962616 0.467285 2.79037 0.467285 5.0243V41.5794L8.98691 33.4561H38.8056C41.1485 33.4561 43.0654 31.6283 43.0654 29.3944V5.0243C43.0654 2.79037 41.1485 0.962616 38.8056 0.962616Z" fill="#FF9900" />
                </svg>
              </Grid>
            </Grid>

            <Grid marginTop={3} padding={2} container>
              <Grid item xs={12} textAlign="center">
                <Typography color="#545454" fontSize={30} fontWeight={600}>{outOfTarget}</Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Card style={{ height: "100%" }}>
            <Grid padding={2} container>
              <Grid item xs={12} sm={6} md={6}>
                <Typography color="#707070">TOTAL INVOICE</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} textAlign="right">
                <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.7042 35.0592C13.4103 35.0592 14.0027 34.8199 14.4812 34.3414C14.958 33.8646 15.1964 33.2731 15.1964 32.567V20.1059C15.1964 19.3998 14.958 18.8075 14.4812 18.329C14.0027 17.8521 13.4103 17.6137 12.7042 17.6137C11.9981 17.6137 11.4058 17.8521 10.9273 18.329C10.4504 18.8075 10.212 19.3998 10.212 20.1059V32.567C10.212 33.2731 10.4504 33.8646 10.9273 34.3414C11.4058 34.8199 11.9981 35.0592 12.7042 35.0592ZM22.6731 35.0592C23.3792 35.0592 23.9715 34.8199 24.45 34.3414C24.9269 33.8646 25.1653 33.2731 25.1653 32.567V12.6293C25.1653 11.9231 24.9269 11.3308 24.45 10.8523C23.9715 10.3755 23.3792 10.1371 22.6731 10.1371C21.9669 10.1371 21.3755 10.3755 20.8986 10.8523C20.4201 11.3308 20.1809 11.9231 20.1809 12.6293V32.567C20.1809 33.2731 20.4201 33.8646 20.8986 34.3414C21.3755 34.8199 21.9669 35.0592 22.6731 35.0592ZM32.6419 35.0592C33.348 35.0592 33.9395 34.8199 34.4164 34.3414C34.8949 33.8646 35.1341 33.2731 35.1341 32.567V27.5825C35.1341 26.8764 34.8949 26.2841 34.4164 25.8056C33.9395 25.3288 33.348 25.0903 32.6419 25.0903C31.9358 25.0903 31.3443 25.3288 30.8675 25.8056C30.389 26.2841 30.1497 26.8764 30.1497 27.5825V32.567C30.1497 33.2731 30.389 33.8646 30.8675 34.3414C31.3443 34.8199 31.9358 35.0592 32.6419 35.0592ZM5.22759 45.028C3.85687 45.028 2.68304 44.5404 1.70609 43.5651C0.730807 42.5882 0.243164 41.4143 0.243164 40.0436V5.15264C0.243164 3.78192 0.730807 2.60809 1.70609 1.63114C2.68304 0.655855 3.85687 0.168213 5.22759 0.168213H40.1186C41.4893 0.168213 42.6631 0.655855 43.64 1.63114C44.6153 2.60809 45.103 3.78192 45.103 5.15264V40.0436C45.103 41.4143 44.6153 42.5882 43.64 43.5651C42.6631 44.5404 41.4893 45.028 40.1186 45.028H5.22759Z" fill="#53E62E" />
                </svg>
              </Grid>
            </Grid>

            <Grid marginTop={3} padding={2} container>
              <Grid item xs={12} textAlign="center">
                <Typography color="#545454" fontSize={30} fontWeight={600}>{totals.TOTAL}</Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>

      <div className="card-pipeline-container">
        {
          Object.entries(statuses).map(([key, value]) => {
            const { IN_SLA, OUT_SLA } = value;
            return (
              <CardPipeline
                key={key}
                status={key}
                inSla={IN_SLA}
                outSla={OUT_SLA}
                selectedStatus={searchParams.ID_STATUS_INVOICE}
                selectedSla={searchParams.IN_SLA}
                onSelectStatus={onChangeSearchParam('ID_STATUS_INVOICE')}
                onSelectSLA={handleSelectSLA}
              />
            );
          })
        }
      </div>

      <Grid marginTop={8}>
        <DashboardInvoicesTable
          data={data}
          companies={companies}
          centers={centers}
          invoiceTypes={invoiceTypes}
          searchParams={searchParams}
          reset={reset}
          onChangeSearchParam={onChangeSearchParam}
          fetchData={fetchData}
          onUpdate={loadData}
          onPaginationModelChange={(e: any) => {
            setPaginationModel(e);
            setNewSearchParams((prevState) => ({
              ...prevState,
              ...parseToSearchParams(urlSearchParams),
              page: e.page + 1, limit: e.pageSize
            })
            );
            setIsFetching(Math.random());
          }}
          pagination
          initialState={{
            pagination: { paginationModel: { pageSize: searchParams.limit } },
            ...(searchParams?.ORDER_FIELD ? {
              sorting: { sortModel: [{ field: searchParams.ORDER_FIELD, sort: searchParams.ORDER_SORT }] }
            } : null)
          }}
          rowCount={total}
          paginationModel={paginationModel}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          paginationMode="server"
          onSortModelChange={([{ field, sort } = {
            field: searchParams.ORDER_FIELD,
            sort: (searchParams.ORDER_SORT === 'desc' ? 'asc' : 'desc') as SearchParams['ORDER_SORT']
          }]) => {
            console.log({ field, sort });
            setNewSearchParams((prevState) => ({
              ...prevState,
              ORDER_FIELD: field,
              ORDER_SORT: sort
            }));
            setIsFetching(Math.random());
          }}
        />
      </Grid>

      {
        graphics && (
          <Grid container spacing={2} marginTop={8}>
            <Grid item xs={12} sm={6}>
              {
                graphics?.transportationMode && <CardGraphic
                  title="Transportation Mode"
                  data={handleFormatGraphicData(graphics?.transportationMode)}
                />
              }
            </Grid>
            <Grid item xs={12} sm={6}>
              {
                graphics?.serviceLevel && <CardGraphic
                  title="Service Level"
                  data={handleFormatGraphicData(graphics?.serviceLevel)}
                />
              }
            </Grid>
            <Grid item xs={12} sm={6}>
              {
                graphics?.origin && <CardGraphic
                  title="Origin"
                  data={handleFormatGraphicData(graphics?.origin)}
                />
              }
            </Grid>
            <Grid item xs={12} sm={6}>
              {
                graphics?.destination && <CardGraphic
                  title="Destination"
                  data={handleFormatGraphicData(graphics?.destination)}
                />
              }
            </Grid>
          </Grid>
        )
      }
    </div>
  );
}
