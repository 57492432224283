import React, { useEffect, useState } from "react";

import { api } from "../../lib/api";
import { getToken } from "../../storage/auth";
import { Process, SaveBoardingProcessData } from "../../types/process";
import { enqueueSnackbar } from "notistack";

interface IList {
  keyword?: string;
  page?: number;
  limit?: number;
  sort?: string;
}

const entity = "boardingProcess";

export const useList = (
  params?: IList,
  token?: string,
):
  | {
    process: Process;
    isLoading: boolean,
    processCode: string,
    setProcessCode: React.Dispatch<React.SetStateAction<string>>
    setRefetch: React.Dispatch<React.SetStateAction<boolean>>
  } => {
  const [isLoading, setIsLoading] = useState(false);
  const [process, setProcess] = useState<Process>({} as Process);
  const [processCode, setProcessCode] = useState<string>("");
  const [refetch, setRefetch] = useState<boolean>(false);

  useEffect(() => {
    if (processCode !== "" && refetch) {
      (async function () {
        setIsLoading(true);
        api.get(`${entity}/findByCode?code=${processCode}`, {
          headers: { authorization: `Bearer ${token}` },
        })
          .then((response) => {
            const { data } = response;
            setProcess(data.object);

            if (!data.success) {
              if (data.message[0] === "Register not found") {
                enqueueSnackbar("Boarding Process not found", { variant: "error" });
                setIsLoading(false);
                return;
              } else {
                enqueueSnackbar(data.message[0], { variant: "error" });
              }
            }

            setProcessCode("");
            setRefetch(false);
            setIsLoading(false);
          })
      })();
    }
  }, [params, token, refetch, process, processCode, isLoading]);

  return { process, isLoading, processCode, setProcessCode, setRefetch };
};

export const save = async (saveBoardingProcessData: SaveBoardingProcessData) => {
  const token = getToken();
  const formData = new FormData();
  saveBoardingProcessData.boardingProcessAttachments?.forEach((attachment) => {
    if (!attachment?.ID_PROCESSO_ANEXO) {
      formData.append("boardingProcessAttachmentsFiles", attachment.FILE);
      attachment.FILE_NAME = attachment.FILE.name
    }
  });

  const jsonData = JSON.stringify(saveBoardingProcessData);

  formData.append("data", jsonData);

  const { data } = await api.post(`/${entity}`, formData, {
    headers: {
      authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data"
    },
  });

  return data;
};

export const saveAttachments = async (saveBoardingProcessData: Partial<SaveBoardingProcessData>) => {
  const token = getToken();
  const formData = new FormData();
  saveBoardingProcessData.boardingProcessAttachments?.forEach((attachment) => {
    if (!attachment?.ID_PROCESSO_ANEXO) {
      formData.append("boardingProcessAttachmentsFiles", attachment.FILE);
      attachment.FILE_NAME = attachment.FILE.name
    }
  });

  const jsonData = JSON.stringify(saveBoardingProcessData);

  formData.append("data", jsonData);

  const { data } = await api.post(`/${entity}/attachments`, formData, {
    headers: {
      authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data"
    },
  });

  return data;
};

export const insertInvoice = async (wrOrInvoiceNum: string) => {
  const token = getToken();
  const { data } = await api.post(`/${entity}/insert-invoice`, { wrOrInvoiceNum }, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
}

export const getById = async (boardingProcessId: number) => {
  const token = getToken();
  const { data } = await api.get(`/${entity}/${boardingProcessId}`, {
    headers: { authorization: `Bearer ${token}` },
  });

  return data;
}

export const findByCode = async (processCode: string) => {
  const { data } = await api.get(`${entity}/findByCode?code=${processCode}`, {
    headers: { authorization: `Bearer ${getToken()}` },
  })
  return data;
}

export const getAttachmentFile = async (id: string | number) => {
  const { data } = await api.get(`${entity}/download/${id}`, {
    headers: { authorization: `Bearer ${getToken()}` },
    responseType: "blob",
  })
  return data;
}